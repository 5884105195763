import React from 'react';
import PropTypes from 'prop-types';
import { Footer as MarketingFooter } from '@chromaui/tetra';

import { footerColumns, footerSocialLinks, homeLink } from './footerData';

export const Footer = ({ theme = 'light' }) => (
  <MarketingFooter
    theme={theme}
    columns={footerColumns}
    socialLinks={footerSocialLinks}
    homeLink={homeLink}
  />
);

Footer.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
};
